import React from "react";

//Styled components imports
import Wrapper from "./Wrapper";
import Text from "./Text";
import Button from "./Button";
import ButtonsContainer from "./ButtonsContainer";

const MoreInfo = () => {
  return (
    <Wrapper>
      <Text>
        Pro více informací o produktu, ceně a možnostech dodání je nutné se
        domluvit e-mailem či telefonicky.
      </Text>
      <ButtonsContainer>
        <Button href="mailto:srb.ludek@seznam.cz">Napsat e-mail</Button>
        <Button href="tel:+420602148862">Zavolat</Button>
      </ButtonsContainer>
    </Wrapper>
  );
};

export default MoreInfo;
