import styled from "styled-components";

const Text = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-top: 48px;
  margin-bottom: 34px;
  text-align: center;
`;

export default Text;
