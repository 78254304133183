import React from "react";

//React components imports
import Layout from "../components/Layout";
import CoinTimerContent from "../components/CoinTimerContent";

const CoinTimerPage = () => {
  return (
    <Layout>
      <CoinTimerContent />
    </Layout>
  );
};

export default CoinTimerPage;
