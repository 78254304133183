import React from "react";

import coinTimerImgOne from "../../images/coin-timer-1.png";
import coinTimerImgTwo from "../../images/coin-timer-2.png";
import coinTimerImgThree from "../../images/coin-timer-3.png";
import coinTimerImgFour from "../../images/coin-timer-4.png";

//React components imports
import Title from "../Title";
import MoreInfo from "../MoreInfo";

//Styled components imports
import Wrapper from "./Wrapper";
import Text from "../../shared-styled-components/Text";
import BoldText from "../../shared-styled-components/BoldText";
import Container from "./Container";
import Image from "./Image";

const CoinTimerContent = () => {
  return (
    <Wrapper>
      <Title>COIN TIMER</Title>
      <Container>
        <Text left>
          Potřebujete spínat spotřebiče, jako placenou službu? Je tu{" "}
          <BoldText>COIN TIMER</BoldText>. Vhodný pro ubytovny, kanceláře,
          společenské místnosti, firmy, ale i pro soukromníky. Je možné spínat
          jakýkoli spotřebič do max příkonu 3600W (dle spínacího relé). Zařízení
          se sestává s displeje a desky elektroniky řízené mikropočítačem Atmel
          a spínací jednotky. Ovládání je jednoduché, bezobslužné a v daném
          případě se spouští pouze vhozením mince.
        </Text>
        <Image src={coinTimerImgOne} alt="Coin timer" />
      </Container>

      <Container>
        <Image src={coinTimerImgTwo} alt="Coin timer" />
        <Text left>
          <BoldText>Princip ovládání :</BoldText>
          <br></br> Nejprve se rozhodneme, kolik bude stát daná služba a jak
          dlouho bude trvat. <br></br>– cena služby – jeden impuls – je dán
          nastavením mincovního přístroje a to v základním provedení 5 – 20 Kč.
          <br></br> – délka služby – čas – se nastavuje po jedné minutě v
          rozmezí 1-31 minut.
        </Text>
      </Container>

      <Text>
        <BoldText>Příklad :</BoldText>
        <br></br>
        Chceme pustit zákazníkovi např. vařič na 10 minut za cenu 5,- Kč.
        <br></br>– nastavíme na mincovním přístroji cenu 5,- Kč / impuls
        <br></br>– na desce elektroniky nastavíme čas 10 min – dle tabulky
        Jumper 2 a 8<br></br>
        Vhozením mince se na displeji objeví čas 10minut a postupně se odečítá
        vnitřními hodinami. Jakmile dosáhne času 1 minuta do konce, pípne a
        další odečítání je již po jedné sekundě. Po dokončení času se objeví na
        displeji 0 a relé odpojí napájení spotřebiče. Kdykoli, i během odčítání,
        ale můžeme vhodit další mince a čas se nám prodlouží. V tomto případě se
        na displeji objeví čas 10 minut po vhození 5Kč mince nebo 20 min po
        vhození 10Kč mince (ale i dvou pětikorun), nebo 40min po vhození jedné
        dvacetikoruny.
      </Text>

      <Container>
        <Text left>
          <BoldText>Princip činnosti :</BoldText>
          <br></br>
          Srdcem časovače je – kromě mincovního přístroje – jednočipový
          mikroprocesor Atmel, který řídí veškerou činnost a to jak dvoumístný
          displej pracující v mutiplexním režimu, tak i časování dle tabulky,
          tlačítek a LED diody. Časovač má vlastní spínaný zdroj.<br></br>
          Význam ovládacích prvků :<br></br>– K1 napájecí konektor 6-35V odběr
          cca 20mA<br></br>– K2 LED dioda kontrolka činnosti<br></br>– K3
          externí tlačítko STOP vypnutí spotřebiče – nulování času<br></br>– K4
          výstup pro spínací jednotku<br></br>– K5 vstup od mincovního přístroje
        </Text>
        <Image src={coinTimerImgThree} alt="Coin timer" />
      </Container>

      <Container>
        <Image swapOrder src={coinTimerImgFour} alt="Coin timer" />
        <Text left>
          Nastavení času :<br></br>
          J1=1min J2=2 min J3=4min J4=8min J5=16min<br></br>
          Čas se nastaví součtem těchto časů zasunutím příslušného Jumperu –
          celkem tedy 1 – 31 minut.<br></br>
          CENA – stavebnice . . . . . . 590,- Kč plus DPH . . . ( bez mincovního
          přístroje )<br></br>
          CENA – komplet . . . . . . . . 990,- Kč plus DPH . . . ( bez
          mincovního přístroje )
        </Text>
      </Container>
      <MoreInfo />
    </Wrapper>
  );
};

export default CoinTimerContent;
