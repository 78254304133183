import styled from "styled-components";

const Image = styled.img`
  display: block;
  max-width: 400;
  align-self: center;
  order: ${props => (props.swapOrder ? "0" : "2")};

  @media (${props => props.theme.mediaQueries.desktop}) {
    order: 0;
  }
`;

export default Image;
