import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin: 34px 0;
  flex-direction: column;
  gap: 16px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    flex-direction: row;
    gap: 35px;
  }
`;

export default Container;
