import styled from "styled-components";

const Button = styled.a`
  width: 179px;
  padding: 10px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-weight: 600;
  background-color: ${props => props.theme.colors.main};
  border-radius: 30px;
  filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    background-color: #27c1c1;
  }
`;

export default Button;
