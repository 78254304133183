import styled from "styled-components";

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 34px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    flex-direction: row;
    justify-content: center;
    > * + * {
      margin-top: 0px;
      margin-left: 34px !important;
    }
  }
`;

export default ButtonsContainer;
